@import "~antd/dist/antd.css";

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("./fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("./fonts/AvenirNextLTPro-Bold.otf");
}

@font-face {
  font-family: "AvenirNextLTPro-It";
  src: url("./fonts/AvenirNextLTPro-It.otf");
}

@font-face {
  font-family: "Nexa W01 Bold Italic";
  src: url("./fonts/Nexa\ W01\ Bold\ Italic.otf");
}

@font-face {
  font-family: "Nexa W01 Book";
  src: url("./fonts/Nexa\ W01\ Book.otf");
}

@font-face {
  font-family: "NexaDemo-Bold";
  src: url("./fonts/NexaBold.ttf");
}

@font-face {
  font-family: "NexaDemo-Light";
  src: url("./fonts/NexaDemo-Light.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html{
  max-width: 100vw;
  overflow-x: hidden!important;
  width: 100vw;
  overscroll-behavior: none; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
