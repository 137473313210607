.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  top: 0;
  /* left: calc(50% - 25px); */
  /* width: 50px; */
  width: 100%;
  left: 0;
  height: 35px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-color: transparent;
  /* outline: red solid thin; */
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: top center; */
  padding: 0 3px 3px 0;
  cursor: pointer;
}